import React, { useState, useRef } from 'react';
import axios from 'axios';

function App() {
  const [transcribedText, setTranscribedText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [audioSrc, setAudioSrc] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);

  // Handle recording
  const handleStartRecording = async (language) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioChunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => handleUpload(language);
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  // Handle stopping recording
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Handle upload and transcription
  const handleUpload = async (language) => {
    setLoading(true); // Start loading
    setTranscribedText('Processing audio...');

    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

    try {
      const response = await axios.post(`/api/transcribe/${language}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      //setTranscribedText(response.data.originalText);
     // setTranslatedText('');
	  // Update state with only the transcribed text
    setTranscribedText(response.data.transcription);
	
    } catch (error) {
      console.error('Error uploading file:', error);
      setTranscribedText('Failed to process audio. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle translation and synthesis
 
  const handleTranslateAndSynthesize = async (targetLangCode) => {
  setLoading(true);
  try {
    // First, translate the text
    const translateResponse = await axios.post('/api/translate', {
      text: transcribedText,
      targetLangCode,
    });

    const translatedText = translateResponse.data.translatedText;
    setTranslatedText(translatedText);

    // Then synthesize the translated text to speech
    const synthesizeResponse = await axios.post(
      '/api/synthesize',
      { text: translatedText, languageCode: targetLangCode },
      { responseType: 'blob' }
    );

    const audioBlob = new Blob([synthesizeResponse.data], { type: 'audio/mp3' });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioSrc(audioUrl);
  } catch (error) {
    console.error('Error translating and synthesizing:', error);
  } finally {
    setLoading(false);
  }
};


  return (
    <div className="App">
      {/* Section 1: Recording English */}
      <div>
	  <h3>Please select your language to record:</h3>
        <button
          onClick={() => handleStartRecording('en-US')}
          disabled={isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
        >
          English
        </button>
        
      
      {/* Section 2: Recording Chinese */}
      
        <button
          onClick={() => handleStartRecording('cmn-Hans-CN')}
          disabled={isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
        >
          请说
        </button>
       
        
    

      {/* Section 3: Recording Spanish */}
    
        <button
          onClick={() => handleStartRecording('es-ES')}
          disabled={isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
        >
          Española
        </button>
        <button
          onClick={handleStopRecording}
          disabled={!isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
        >
          Stop Recording and Display 停 detener
        </button>
      </div>

      {/* Section 4: Transcribed Text */}
      <textarea
        value={loading ? 'Processing...请等待...espere por favor ...' : transcribedText}
        onChange={(e) => setTranscribedText(e.target.value)}
        style={{ width: '100%', height: '100px', margin: '10px 0' }}
        disabled={loading} // Disable editing during processing
      />
      <div>
	  <h3>You can modify the text and translate to:</h3>
  {/* English to Chinese */}
  <button
    onClick={() => handleTranslateAndSynthesize('cmn-Hans-CN')}
    style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
    disabled={loading}
  >
    Chinese
  </button>

  {/* English to Spanish */}
  <button
    onClick={() => handleTranslateAndSynthesize('es-ES')}
    style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
    disabled={loading}
  >
    Spanish
  </button>

  {/* English to English (modify as needed) */}
  <button
    onClick={() => handleTranslateAndSynthesize('en-US')}
    style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
    disabled={loading}
  >
    English
  </button>
</div>


      {/* Section 5: Translation and Audio Output */}
      {audioSrc && (
        <div>
         
          <audio ref={audioRef} controls src={audioSrc} autoPlay />
        </div>
      )}
      <div>
        <h3>Translated Text:</h3>
        <p>{translatedText}</p>
      </div>
    </div>
  );
}

export default App;
